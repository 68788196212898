export default [
  {
    name: "Ballads",
    artist: "Joji",
    number: "01",
    imageName: "ballads",
    bgNumber: "bg01",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "Electronic",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "Trench",
    artist: "Twenty One Pilots",
    number: "02",
    imageName: "trench",
    bgNumber: "bg21",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "Pop",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "Swimming",
    artist: "Mac Miller",
    number: "03",
    imageName: "swimming",
    bgNumber: "bg02",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "Hip Hop",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "Ye",
    artist: "Kanye West",
    number: "04",
    imageName: "ye",
    bgNumber: "bg23",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "Hip Hop",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "Kids See Ghosts",
    artist: "Kids See Ghosts",
    number: "05",
    imageName: "kidsseeghost",
    bgNumber: "bg19",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "Hip Hop",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "Music For Happiness",
    artist: "Joey Pecoraro",
    number: "06",
    imageName: "musicforhappiness",
    bgNumber: "bg22",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "LoFi",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "Singularity",
    artist: "Jon Hopkins",
    number: "07",
    imageName: "singularity",
    bgNumber: "bg14",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "Electronic",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "Black Panther",
    artist: "Kendrick Lamar",
    number: "08",
    imageName: "blackpanther",
    bgNumber: "bg21",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "Hip Hop",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "Uniform Distortion / Clarity",
    artist: "Jim James",
    number: "09",
    imageName: "uniformclarity",
    bgNumber: "bg01",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "Psychadelic Rock",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "Steel Mogu / Clear Tamei",
    artist: "Iglooghost",
    number: "10",
    imageName: "steelmogu",
    bgNumber: "bg13",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "Electronic",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "Con Todo El Mundo",
    artist: "Khruangbin",
    number: "11",
    imageName: "contodoelmundo",
    bgNumber: "bg14",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "Psychadelic Rock",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "Care For Me",
    artist: "Saba",
    number: "12",
    imageName: "careforme",
    bgNumber: "bg01",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "Hip Hop",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "Half Mile Harvest",
    artist: "The Teskey Brothers",
    number: "13",
    imageName: "halfmileharvest",
    bgNumber: "bg12",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "Blues",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "Decaprio 2",
    artist: "JID",
    number: "14",
    imageName: "decaprio",
    bgNumber: "bg14",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "Hip Hop",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "Lush",
    artist: "Snail Mail",
    number: "15",
    imageName: "lush",
    bgNumber: "bg18",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "Indie",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "Irene",
    artist: "Medasin",
    number: "16",
    imageName: "irene",
    bgNumber: "bg01",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "Electronic",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "Solace",
    artist: "Rufus Du Sol",
    number: "17",
    imageName: "solace",
    bgNumber: "bg03",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "Electronic",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "Nova",
    artist: "RL Grime",
    number: "18",
    imageName: "nova",
    bgNumber: "bg06",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "Electronic",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "Hive Mind",
    artist: "The Internet",
    number: "19",
    imageName: "hivemind",
    bgNumber: "bg09",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "R&B",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "Room 25",
    artist: "Noname",
    number: "20",
    imageName: "room25",
    bgNumber: "bg12",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "Hip Hop",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "Good Thing",
    artist: "Leon Bridges",
    number: "21",
    imageName: "goodthing",
    bgNumber: "bg13",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "Soul",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "Being So Normal",
    artist: "Peach Pit",
    number: "22",
    imageName: "beingsonormal",
    bgNumber: "bg19",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "Indie Pop",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "Superorganism",
    artist: "Superorganism",
    number: "23",
    imageName: "superorganism",
    bgNumber: "bg21",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "Indie Pop",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "Collagically Speaking",
    artist: "R+R=NOW",
    number: "24",
    imageName: "collagicallyspeaking",
    bgNumber: "bg20",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "Jazz",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "Now Only",
    artist: "Mount Eerie",
    number: "25",
    imageName: "nowonly",
    bgNumber: "bg05",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "Folk",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "Oil of Every Pearl's",
    artist: "SOPHIE",
    number: "26",
    imageName: "oils",
    bgNumber: "bg17",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "Electronic",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "November",
    artist: "Sir",
    number: "27",
    imageName: "november",
    bgNumber: "bg03",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "R&B",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "For Ever",
    artist: "Jungle",
    number: "28",
    imageName: "forever",
    bgNumber: "bg08",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "Funk",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "iridescence",
    artist: "Brockhampton",
    number: "29",
    imageName: "iridescence",
    bgNumber: "bg16",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "R&B/Hip Hop",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "Encore",
    artist: "Anderson East",
    number: "30",
    imageName: "encore",
    bgNumber: "bg05",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "Roots Rock",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "Resistance",
    artist: "Brandon Coleman",
    number: "31",
    imageName: "resistance",
    bgNumber: "bg06",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "Dance/Electronic",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "Nothing is Still",
    artist: "Leon Vynhall",
    number: "32",
    imageName: "nothingisstill",
    bgNumber: "bg15",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "Electronic",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "Passwords",
    artist: "Dawes",
    number: "33",
    imageName: "passwords",
    bgNumber: "bg13",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "Indie Folk",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "ONEPOINTFIVE",
    artist: "Amine",
    number: "34",
    imageName: "onepointfive",
    bgNumber: "bg01",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "Hip Hop",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "KOD",
    artist: "J. Cole",
    number: "35",
    imageName: "kod",
    bgNumber: "bg09",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "Hip Hop",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "Isolation",
    artist: "Kali Uchis",
    number: "36",
    imageName: "isolation",
    bgNumber: "bg14",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "RnB",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "Oxnard",
    artist: "Anderson .Paak",
    number: "37",
    imageName: "oxnard",
    bgNumber: "bg01",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "RnB/Hip Hop",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "Lady Lady",
    artist: "Masego",
    number: "38",
    imageName: "ladylady",
    bgNumber: "bg13",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "Funk/Soul/RnB",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "Hope Downs",
    artist: "Rolling Blackouts Coastal Fever",
    number: "39",
    imageName: "hopedowns",
    bgNumber: "bg12",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "Garage Rock",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "Young Sick Camellia",
    artist: "St. Paul & The Broken Bones",
    number: "40",
    imageName: "camellia",
    bgNumber: "bg06",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "Roots Rock",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "Ephorize",
    artist: "Cupcakke",
    number: "41",
    imageName: "ephorize",
    bgNumber: "bg11",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "Hip Hop",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "Heavan & Earth",
    artist: "Kamasi Washington",
    number: "42",
    imageName: "heavanandearth",
    bgNumber: "bg10",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "Jazz",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "Clean",
    artist: "Soccer Mommy",
    number: "43",
    imageName: "clean",
    bgNumber: "bg06",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "Indie",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "7",
    artist: "Beach House",
    number: "44",
    imageName: "seven",
    bgNumber: "bg01",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "Dream Pop",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "Golden Age",
    artist: "Houndmouth",
    number: "45",
    imageName: "goldenage",
    bgNumber: "bg09",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "Indie Rock",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "The Tree of Forgiveness",
    artist: "John Prine",
    number: "46",
    imageName: "treeofforgiveness",
    bgNumber: "bg21",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "Country Folk",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "Feel",
    artist: "Courtney Barnett",
    number: "47",
    imageName: "feel",
    bgNumber: "bg18",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "Indie",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "Hollow Ground",
    artist: "Cut Worms",
    number: "48",
    imageName: "hollowground",
    bgNumber: "bg01",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "Garage Rock",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "Fixed Rope (II)",
    artist: "Express Rising",
    number: "49",
    imageName: "fixedrope",
    bgNumber: "bg08",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "Electronic",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
  },
  {
    name: "Noir",
    artist: "Smino",
    number: "50",
    imageName: "noir",
    bgNumber: "bg05",
    songTitles: [
      "Singularity",
      "Emerald Rush",
      "Neon Pattern Drum",
      "Everything Connected",
      "Feel First Life",
      "C O S M",
      "Echo Dissolve",
      "Luminous Beings",
      "Recovery"
    ],
    genre: "Hip Hop",
    spotifyUrl:
      "https://open.spotify.com/album/1nvzBC1M3dlCMIxfUCBhlO?si=D1BmqyiySLGZSqT7dvuxyg"
   }
];
