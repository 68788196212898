import React, { Component } from 'react';

export default class Premium extends Component {
  render() {
    return (
      <>
        <h1>Premium Page</h1>
      </>
    );
  }
}
